import {
  DirectionsRenderer,
  GoogleMap, Marker, StreetViewPanorama, useJsApiLoader,
} from '@react-google-maps/api';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
// import * as turf from '@turf/turf';
import { addWeeks, differenceInDays } from 'date-fns';
import {
  FinishedContainer,
  FinishedTitle,
  LabelContainer,
  LoadingContainer,
  MapContainer, MedalRequestContainer, MedalRequestInfo, ProgressContainer, ProgressInfoContainer, ProgressInfoWrapper, ProgressLabel, ProgressValue,
  RegisterButton,
  RegisterButtonText,
} from './styles';
import ProgressBar from '../../components/ProgressBar';
import RegisterActivity from '../../components/RegisterActivity';
import { refreshActivities } from '../../store/Activities.store';
import { RootState } from '../../store';
import theme from '../../theme';
import { ChallengeType } from '.';
import coreApi from '../../services/coreApi';
import MedalRequestModal from '../../components/MedalRequest';
import { MedalRequestType } from '../../types/Challenger';
import Origin from '../../assets/challenges/icone-largada.png';
import Destination from '../../assets/challenges/icone-chegada.png';
import StreetIcon from '../../assets/challenges/street-view.png';
import Runner from '../../assets/challenges/icone-usuario.png';
import challengeMapStyle from './mapStyle';
import Loader from '../../components/Loader';

interface Props {
  challenge: ChallengeType,
}

export default function ChallengeProgress({ challenge }: Props) {
  const { isLoaded: mapIsLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API || '',
  });

  const { user } = useSelector((state: RootState) => state.auth);
  const { maps } = window.google;
  const dispatch = useDispatch();

  const streetViewRef = useRef<any>();

  const [directions, setDirections] = useState<any>();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMedalRequest, setModalMedalRequest] = useState(false);
  const [medalRequest, setMedalRequest] = useState<MedalRequestType>();
  const [distance, setDistance] = useState(0);
  const [showStreetView, setShowStreetView] = useState(false);

  const [loading, setLoading] = useState(false);
  const [medalLoading, setMedalLoading] = useState(false);

  const [currentPoint, setCurrentPoint] = useState<any>(null);

  const [isFinished, setIsFinished] = useState(false);

  const startedDate = new Date(challenge.started_date);
  startedDate.setHours(0, 0, 0, 0);

  const finishDate = addWeeks(startedDate, challenge.weeks);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const totalDays = differenceInDays(finishDate, startedDate);
  const remainingDays = differenceInDays(finishDate, today);
  const passedDays = differenceInDays(today, startedDate);

  const daysProgress = (passedDays / totalDays) * 100;

  const totalDistance = 135;
  const remainingDistance = totalDistance - distance;
  const distanceProgress = (distance / totalDistance) * 100;

  const options = mapIsLoaded ? {
    style: challengeMapStyle,
    disableDefaultUI: false,
    fullscreenControl: false,
    clickableIcons: false,
    streetViewControl: false,
    mapTypeControl: true,
    mapTypeId: 'roadmap',
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.DEFAULT,
      position: maps.ControlPosition.TOP_RIGHT,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID,
      ],
    },
  } : {};

  const createStreetViewButton = () => {
    const controlButton = document.createElement('button');
    const image = document.createElement('img');

    image.src = StreetIcon;
    image.alt = 'Street View Button';
    image.width = 30;
    image.height = 30;

    controlButton.style.border = '2px solid #fff';
    controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlButton.style.cursor = 'pointer';
    controlButton.style.textAlign = 'center';
    controlButton.style.marginRight = '10px';
    controlButton.style.padding = '3px';
    controlButton.type = 'button';

    controlButton.appendChild(image);

    controlButton.addEventListener('click', () => {
      setShowStreetView(true);
    });

    return controlButton;
  };

  const origin = { lat: -29.17909859886879, lng: -51.60643748126469 };
  const destination = { lat: -29.36381049911687, lng: -50.80998726018114 };

  const getDistance = async () => {
    setLoading(true);
    coreApi.get(`/user-activity/challenge/${user.id}/${challenge.started_date}`)
      .then((response) => {
        if (response.data) {
          setDistance(response.data.distance);
          if (response.data.distance >= totalDistance) {
            setIsFinished(true);
          }
        }
        setLoading(false);
      })
      .catch(() => {
        toast.error('Erro ao calcular a distância');
        setLoading(false);
      });
  };

  const refresh = () => {
    toast.success('Atividade criada com sucesso!');
    getDistance();
    dispatch(refreshActivities(user.id) as any);
  };

  function updateUserPosition(distance: number) {
    if (!directions) return null;
    const distanceTraveled = distance * 1000;
    let coveredDistance = 0;
    const routePath = directions.routes[0].overview_path;
    for (let i = 0; i < routePath.length - 1; i++) {
      const segmentDistance = maps.geometry.spherical.computeDistanceBetween(
        routePath[i],
        routePath[i + 1],
      );

      if (coveredDistance + segmentDistance >= distanceTraveled) {
        const ratio = (distanceTraveled - coveredDistance) / segmentDistance;
        const lat = routePath[i].lat() + ratio * (routePath[i + 1].lat() - routePath[i].lat());
        const lng = routePath[i].lng() + ratio * (routePath[i + 1].lng() - routePath[i].lng());

        return new maps.LatLng(lat, lng);
      }
      coveredDistance += segmentDistance;
    }
    return null;
  }

  // const addPointAtDistanceAlongRoute = (distanceInKm: number) => {
  //   if (!directions) return null;

  //   const route = directions.routes[0].legs[0];
  //   const totalDistance = route.distance.value / 1000;
  //   const targetDistance = Math.min(distanceInKm, totalDistance);

  //   let accumulatedDistance = 0;
  //   const points = route.steps.map((step: any) => {
  //     const start = [step.start_location.lng(), step.start_location.lat()];
  //     const end = [step.end_location.lng(), step.end_location.lat()];
  //     const stepDistance = step.distance.value / 1000;

  //     if (accumulatedDistance + stepDistance >= targetDistance) {
  //       const remainingDistance = targetDistance - accumulatedDistance;

  //       const interpolatedPoint = turf.along(turf.lineString([start, end]), remainingDistance, { units: 'kilometers' });

  //       return { lat: interpolatedPoint.geometry.coordinates[1], lng: interpolatedPoint.geometry.coordinates[0] };
  //     }

  //     accumulatedDistance += stepDistance;
  //     return null;
  //   });

  //   return points.find((point: any) => point !== null);
  // };

  const getMedalRequest = () => {
    setMedalLoading(true);
    if (isFinished) {
      coreApi.get(`medal-request/user/${user.id}/${challenge.slug}`)
        .then((response) => {
          if (response.data) {
            setMedalRequest(response.data);
          }
          setMedalLoading(false);
        }).catch(() => {
          setMedalLoading(false);
        });
    }
  };

  useEffect(() => {
    if (mapIsLoaded) {
      const DirectionsService = new maps.DirectionsService();

      DirectionsService.route({
        origin: new maps.LatLng(origin.lat, origin.lng),
        destination: new maps.LatLng(destination.lat, destination.lng),
        travelMode: maps.TravelMode.DRIVING,
        waypoints: [
          { location: new maps.LatLng(-29.314204680650942, -51.1673566766163), stopover: false },
        ],
      }, (result, status) => {
        if (status === maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      });
    }
  }, [mapIsLoaded]);

  useEffect(() => {
    getDistance();
  }, [challenge]);

  useEffect(() => {
    getMedalRequest();
  }, [isFinished]);

  useEffect(() => {
    if (distance >= totalDistance) {
      setCurrentPoint(destination);
    } else {
      setCurrentPoint(updateUserPosition(distance));
    }
  }, [distance, directions]);

  return (
    <>
      <RegisterActivity
        visible={modalVisible}
        close={() => setModalVisible(false)}
        refresh={refresh}
        _type="Run"
      />
      <MedalRequestModal isVisible={modalMedalRequest} setVisible={setModalMedalRequest} user_id={user.id} challenge={challenge} refresh={getMedalRequest} />
      {!loading && (
        <>
          {mapIsLoaded && (
            <MapContainer>
              <GoogleMap
                mapContainerStyle={{
                  width: '100%',
                  height: '100%',
                }}
                zoom={13}
                options={options}
                onLoad={(map) => {
                  const controlDiv = document.createElement('div');

                  const streetViewButton = createStreetViewButton();

                  controlDiv.appendChild(streetViewButton);

                  map.controls[maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
                }}
              >
                {directions && <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }} />}
                <Marker
                  position={origin}
                  icon={{
                    url: Origin,
                    scaledSize: new maps.Size(40, 40),
                    origin: new maps.Point(0, 0),
                  }}
                />
                <Marker
                  position={destination}
                  icon={{
                    url: Destination,
                    scaledSize: new maps.Size(40, 40),
                    origin: new maps.Point(0, 0),
                  }}
                />
                {currentPoint && distance > 0 && (
                  <Marker
                    position={currentPoint}
                    icon={{
                      url: Runner,
                      scaledSize: new maps.Size(30, 40),
                      origin: new maps.Point(0, 0),
                    }}
                  />
                )}
                {currentPoint && (
                  <StreetViewPanorama
                    ref={streetViewRef}
                    options={{
                      position: currentPoint,
                      visible: showStreetView,
                      fullscreenControl: false,
                      enableCloseButton: true,
                      addressControlOptions: { position: maps.ControlPosition.TOP_RIGHT },
                    }}
                    onVisibleChanged={() => {
                      if (streetViewRef.current && streetViewRef.current.state.streetViewPanorama.visible === false) {
                        setShowStreetView(false);
                      }
                    }}
                  />
                )}
              </GoogleMap>
            </MapContainer>
          )}
          {!isFinished ? (
            <ProgressContainer>
              <ProgressInfoWrapper>
                <ProgressInfoContainer>
                  <LabelContainer>
                    <ProgressLabel color="#00CD00">Concluídos</ProgressLabel>
                    <ProgressValue>{`${distance.toFixed(1)}km`}</ProgressValue>
                  </LabelContainer>
                  <LabelContainer>
                    <ProgressLabel>Restantes</ProgressLabel>
                    <ProgressValue>{`${remainingDistance.toFixed(1)}km`}</ProgressValue>
                  </LabelContainer>
                </ProgressInfoContainer>
              </ProgressInfoWrapper>
              <ProgressBar label="distância" color="#00CD00" value={distanceProgress} />
              <ProgressBar label="Tempo" color="#D2E6B0" value={daysProgress} />
              <ProgressInfoWrapper>
                <ProgressInfoContainer>
                  <LabelContainer>
                    <ProgressLabel color="#D2E6B0">Decorridos</ProgressLabel>
                    <ProgressValue>{`${passedDays} Dias`}</ProgressValue>
                  </LabelContainer>
                  <LabelContainer>
                    <ProgressLabel>Restantes </ProgressLabel>
                    <ProgressValue>{`${remainingDays} Dias`}</ProgressValue>
                  </LabelContainer>
                </ProgressInfoContainer>
              </ProgressInfoWrapper>
              <RegisterButton onClick={() => setModalVisible(true)}>
                <RegisterButtonText>Registrar atividade manual</RegisterButtonText>
              </RegisterButton>
            </ProgressContainer>
          ) : (
            <FinishedContainer>
              <FinishedTitle>Parabéns, você concluiu o desafio!</FinishedTitle>
              <ProgressInfoContainer full>
                <LabelContainer>
                  <ProgressLabel color="#00CD00">Concluídos</ProgressLabel>
                  <ProgressValue>135km</ProgressValue>
                </LabelContainer>
                <LabelContainer>
                  <ProgressLabel>Restantes</ProgressLabel>
                  <ProgressValue>0km</ProgressValue>
                </LabelContainer>
              </ProgressInfoContainer>
              <ProgressBar label="" color="#00CD00" value={distanceProgress} full />
              {!medalRequest && !medalLoading && (
                <RegisterButton onClick={() => setModalMedalRequest(true)} color="white">
                  <RegisterButtonText color={theme.colors.primary}>Solicite sua medalha</RegisterButtonText>
                </RegisterButton>
              )}
              {medalRequest && medalRequest?.status === 'SENT' && !medalLoading && (
                <MedalRequestContainer>
                  <MedalRequestInfo>Medalha enviada.</MedalRequestInfo>
                  <MedalRequestInfo>Confira em seu e-mail o link de rastreio da remessa.</MedalRequestInfo>
                </MedalRequestContainer>
              )}
              {medalRequest && medalRequest?.status === 'REQUESTED' && !medalLoading && (
                <MedalRequestContainer>
                  <MedalRequestInfo>Medalha em preparação para envio.</MedalRequestInfo>
                  <MedalRequestInfo>Você receberá o link de rastreio em breve no seu e-mail.</MedalRequestInfo>
                </MedalRequestContainer>
              )}
            </FinishedContainer>
          )}
        </>
      )}
      {loading && (
        <LoadingContainer>
          <Loader color="white" />
        </LoadingContainer>
      )}
    </>
  );
}
