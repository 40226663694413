import React, { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import BaseModal from '../BaseModal';
import FormInput from '../FormInput';
import {
  ButtonContainer, Container, MaskedInputContainer, RowContainer,
} from './styles';
import MaskedInput from '../MaskedInput';
import ModalButton from '../ModalButton';
import coreApi from '../../services/coreApi';
import { ChallengeType } from '../../pages/ChallengePage';

interface Props {
  isVisible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  user_id: number;
  challenge: ChallengeType;
  refresh: () => void
}

export default function MedalRequestModal({
  isVisible, setVisible, user_id, refresh, challenge,
}: Props) {
  const [cep, setCep] = useState('');
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [neighborhood, setNeighborhood] = useState('');

  const cepAutocomplete = (value: string) => {
    const regex = /^\d{5}-\d{3}$/;
    if (regex.test(value)) {
      axios.get(`https://viacep.com.br/ws/${value}/json/`)
        .then((response) => {
          const { data } = response;
          setAddress(data.logradouro);
          setCity(data.localidade);
          setState(data.uf);
          setNeighborhood(data.bairro);
        });
    }
  };

  const saveRequestMedal = () => {
    if (!(cep || address || number || city || state)) {
      toast.error('Preencha o formulário corretamente');
      return;
    }

    if (Number.isNaN(Number(number))) {
      toast.error('Insira um número válido!');
      return;
    }

    const data = {
      cep,
      address,
      number: +number,
      complement,
      city,
      state,
      neighborhood,
      user_id,
      challenge_slug: challenge.slug,
      challenge_id: challenge._id,
      status: 'REQUESTED',
    };

    coreApi.post('/medal-request', data)
      .then((() => {
        toast.success('Solicitação enviada!');
        refresh();
        setVisible(false);
      }))
      .catch(() => toast.error('Erro ao enviar a solicitação'));
  };

  return (
    <BaseModal isVisible={isVisible} setIsVisible={setVisible} title="SOLICITAR MEDALHA">
      <Container>
        <MaskedInputContainer>
          <MaskedInput
            mask="99999-999"
            name="cep"
            type="text"
            label="CEP"
            inputValue={cep}
            onChange={(e) => {
              cepAutocomplete(e.target.value);
              setCep(e.target.value);
            }}
          />
        </MaskedInputContainer>
        <RowContainer>
          <FormInput
            name="address"
            type="text"
            label="Endereço"
            inputValue={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </RowContainer>
        <RowContainer>
          <FormInput
            name="number"
            type="text"
            label="Número"
            inputValue={number}
            onChange={(e) => setNumber(e.target.value)}
          />
          <FormInput
            name="neighborhood"
            type="text"
            label="Bairro"
            inputValue={neighborhood}
            onChange={(e) => setNeighborhood(e.target.value)}
          />
        </RowContainer>
        <RowContainer>
          <FormInput
            name="city"
            type="text"
            label="Cidade"
            inputValue={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <FormInput
            name="state"
            type="text"
            label="Estado"
            inputValue={state}
            onChange={(e) => setState(e.target.value)}
          />
        </RowContainer>
        <RowContainer>
          <FormInput
            name="complement"
            type="text"
            label="Complemento (opcional)"
            inputValue={complement}
            onChange={(e) => setComplement(e.target.value)}
          />
        </RowContainer>
        <ButtonContainer>
          <ModalButton title="SOLICITAR" onPress={saveRequestMedal} style={{ padding: 8, marginTop: 43, marginBottom: 33 }} />
        </ButtonContainer>
      </Container>
    </BaseModal>
  );
}
